import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from '@/stores/auth';
import Swal from 'sweetalert2/dist/sweetalert2';

const routes = [
    {
        path: '/auth',
        component: () => import('@/layouts/BlankTemplate.vue'),
        children: [
            {
                path: 'login',
                name: 'login',
                component: () => import('@/views/auth/LoginView.vue'),
                meta: {
                    title: 'Login',
                    requiresAuth: false
                }
            },
            {
                path: 'forgot-password',
                name: 'forgot-password',
                component: () => import('@/views/auth/ForgotPassword.vue'),
                meta: {
                    title: 'Lupa Password',
                    requiresAuth: false
                }
            },
            {
                path: 'reset-password',
                name: 'reset-password',
                component: () => import('@/views/auth/ResetPassword.vue'),
                meta: {
                    title: 'Reset Password',
                    requiresAuth: false,
                }
            },
            {
                path: 'forgot-success',
                name: 'forgot-success',
                component: () => import('@/views/auth/ForgotSuccess.vue'),
                meta: {
                    title: 'Lupa Password Berhasil',
                    requiresAuth: false,
                }
            },
            {
                path: 'reset-success',
                name: 'reset-success',
                component: () => import('@/views/auth/ResetSuccess.vue'),
                meta: {
                    title: 'Reset Password Berhasil',
                    requiresAuth: false,
                }
            },
            
        ]
    },
    {
        path: '/',
        redirect: 'dashboard',
        component: () => import('@/layouts/AdminTemplate.vue'),
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import('@/views/DashboardView.vue'),
                meta: {
                    title: 'Dashboard',
                    requiresAuth: true
                }
            },
            {
                path: 'members',
                name: 'list-members',
                component: () => import('@/views/members/ListView.vue'),
                meta: {
                    title: 'Member',
                    requiresAuth: true
                }
            },
            {
                path: 'members/:id/edit',
                name: 'edit-member',
                component: () => import('@/views/members/DetailView.vue'),
                meta: {
                    title: 'Detail Member',
                    requiresAuth: true
                }
            },
            {
                path: 'members/create',
                name: 'create-member',
                component: () => import('@/views/members/CreateView.vue'),
                meta: {
                    title: 'Tambah Member',
                    requiresAuth: true
                }
            },
            {
                path: 'products',
                name: 'list-products',
                component: () => import('@/views/products/ListView.vue'),
                meta: {
                    title: 'Produk',
                    requiresAuth: true
                }
            },
            {
                path: 'products/:id/edit',
                name: 'edit-product',
                component: () => import('@/views/products/DetailView.vue'),
                meta: {
                    title: 'Detail Produk',
                    requiresAuth: true
                }
            },
            {
                path: 'products/create',
                name: 'create-product',
                component: () => import('@/views/products/CreateView.vue'),
                meta: {
                    title: 'Tambah Produk',
                    requiresAuth: true
                }
            },
            {
                path: 'orders',
                name: 'list-orders',
                component: () => import('@/views/orders/ListView.vue'),
                meta: {
                    title: 'Order',
                    requiresAuth: true
                }
            },
            {
                path: 'orders/:id',
                name: 'detail-order',
                component: () => import('@/views/orders/DetailView.vue'),
                meta: {
                    title: 'Detail Order',
                    requiresAuth: true
                }
            },
            {
                path: 'users',
                name: 'list-users',
                component: () => import('@/views/users/ListView.vue'),
                meta: {
                    title: 'Users',
                    requiresAuth: true
                }
            },
            {
                path: 'users/:id/edit',
                name: 'edit-user',
                component: () => import('@/views/users/DetailView.vue'),
                meta: {
                    title: 'Detail User',
                    requiresAuth: true
                }
            },
            {
                path: 'users/create',
                name: 'create-user',
                component: () => import('@/views/users/CreateView.vue'),
                meta: {
                    title: 'Tambah User',
                    requiresAuth: true
                }
            },
            {
                path: 'roles',
                name: 'list-roles',
                component: () => import('@/views/roles/ListView.vue'),
                meta: {
                    title: 'Role',
                    requiresAuth: true
                }
            },
            {
                path: 'roles/:id/edit',
                name: 'edit-role',
                component: () => import('@/views/roles/DetailView.vue'),
                meta: {
                    title: 'Detail Role',
                    requiresAuth: true
                }
            },
            {
                path: 'roles/create',
                name: 'create-role',
                component: () => import('@/views/roles/CreateView.vue'),
                meta: {
                    title: 'Tambah Role',
                    requiresAuth: true
                }
            },
            {
                path: 'permissions',
                name: 'list-permissions',
                component: () => import('@/views/permissions/ListView.vue'),
                meta: {
                    title: 'Permission',
                    requiresAuth: true
                }
            },
            {
                path: 'permissions/:id/edit',
                name: 'edit-permission',
                component: () => import('@/views/permissions/DetailView.vue'),
                meta: {
                    title: 'Detail Permission',
                    requiresAuth: true
                }
            },
            {
                path: 'permissions/create',
                name: 'create-permission',
                component: () => import('@/views/permissions/CreateView.vue'),
                meta: {
                    title: 'Tambah Permission',
                    requiresAuth: true
                }
            },
            {
                path: 'portfolio',
                name: 'list-portfolio',
                component: () => import('@/views/portfolio/ListView.vue'),
                meta: {
                    title: 'Portfolio',
                    requiresAuth: true
                }
            },
            {
                path: 'portfolio/:id/edit',
                name: 'edit-portfolio',
                component: () => import('@/views/portfolio/DetailView.vue'),
                meta: {
                    title: 'Detail Portfolio',
                    requiresAuth: true
                }
            },
            {
                path: 'portfolio/create',
                name: 'create-portfolio',
                component: () => import('@/views/portfolio/CreateView.vue'),
                meta: {
                    title: 'Tambah Portfolio',
                    requiresAuth: true
                }
            },
            {
                path: 'drive',
                name: 'list-drive',
                component: () => import('@/views/drive/ListView.vue'),
                meta: {
                    title: 'Drive',
                    requiresAuth: true
                }
            },
            {
                path: 'drive/:id/edit',
                name: 'edit-drive',
                component: () => import('@/views/drive/DetailView.vue'),
                meta: {
                    title: 'Detail Drive',
                    requiresAuth: true
                }
            },
            {
                path: 'drive/create',
                name: 'create-drive',
                component: () => import('@/views/drive/CreateView.vue'),
                meta: {
                    title: 'Tambah Drive',
                    requiresAuth: true
                }
            },
            {
                path: 'profile',
                name: 'profile',
                component: () => import('@/views/profile/IndexView.vue'),
                meta: {
                    title: 'Profil',
                    requiresAuth: true
                }
            },
            {
                path: 'profile/change',
                name: 'change-profile',
                component: () => import('@/views/profile/ChangeProfile.vue'),
                meta: {
                    title: 'Ubah Profil',
                    requiresAuth: true
                }
            },
            {
                path: 'profile/change-password',
                name: 'change-password',
                component: () => import('@/views/profile/ChangePassword.vue'),
                meta: {
                    title: 'Ubah Password',
                    requiresAuth: true
                }
            },
            {
                path: 'notification',
                name: 'notification',
                component: () => import('@/views/NotificationView.vue'),
                meta: {
                    title: 'Notifikasi',
                    requiresAuth: true
                }
            },
            {
                path: "/forbidden",
                name: 'forbidden',
                meta: {
                    title: 'Forbidden',
                    requiresAuth: true
                },
                component: () => import('@/views/ForbiddenView.vue')
            },
        ]
    },
    {
        path: "/:pathMatch(.*)*",
        name: 'page-not-found',
        meta: {
            title: 'Halaman Tidak Ditemukan',
            requiresAuth: false
        },
        component: () => import('@/views/PageNotfound.vue')
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore()
    const permission = authStore.permission
    document.title = to.meta.title || 'Dzulfikar Nurfikri'
    // console.log(to.path)
    // console.log('user ini akses menu ini ', permission.includes(to.name))

    if(to.matched.some((record) => record.meta['requiresAuth']) && authStore.isAuthenticated === false){
        Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Anda harus login terlebih dahulu"
        });
        next({ path: '/auth/login' })
    } else {
        if(authStore.isAuthenticated){
            // if(to.path === '/auth/login'){
            //     next({ path: '/dashboard' })
            // } else if(to.path === '/auth/forgot-password'){
            //     next({ path: '/dashboard' })
            // } else if(to.path === '/auth/forgot-success'){
            //     next({ path: '/dashboard' })
            // } else if(to.path === '/auth/reset-password'){
            //     next({ path: '/dashboard' })
            // } else if(to.path === '/auth/reset-success'){
            //     next({ path: '/dashboard' })
            // } else {
            //     next()
            // }
            if(to.matched.some((row) => row.meta['requiresAuth'] === false)){
                next({ path: '/dashboard' })
            } else {
                if(to.path == '/forbidden'){
                    next()
                }else if(permission.includes(to.name)){
                    next()
                } else {
                    next({ path: '/forbidden' })
                }
            }
        } else {
            next()
        }
    }
    // next()
})

export default router