import { defineStore } from 'pinia'
import { ref } from 'vue'
import {Buffer} from 'buffer'

export const useAuthStore = defineStore('auth', () => {
    const token = ref(localStorage.getItem('token') ? JSON.parse(Buffer.from(localStorage.getItem('token'), 'base64').toString('utf-8')) : '')
    const user = ref(localStorage.getItem('user') ? JSON.parse(Buffer.from(localStorage.getItem('user'), 'base64').toString('utf-8')) : {})
    const permission = ref(localStorage.getItem('permission') ? JSON.parse(Buffer.from(localStorage.getItem('permission'), 'base64').toString('utf-8')) : [])
    const notifikasiStatus = ref(false)
    const notifikasiMessage = ref('')
    const notifikasiType = ref('alert-success')
    const isAuthenticated = ref(localStorage.getItem('isAuthenticated') ? JSON.parse(Buffer.from(localStorage.getItem('isAuthenticated'), 'base64').toString('utf-8')) : false)
    const sendEmail = ref('')

    const setNotifikasi = (payloads) => {
        notifikasiStatus.value = payloads.status
        notifikasiMessage.value = payloads.message
        notifikasiType.value = payloads.type
    }

    const setAuth = (payloads) => {
        localStorage.setItem('token', Buffer.from(JSON.stringify(payloads.token)).toString('base64'))
        localStorage.setItem('user', Buffer.from(JSON.stringify(payloads.user)).toString('base64'))
        localStorage.setItem('permission', Buffer.from(JSON.stringify(payloads.permission)).toString('base64'))
        localStorage.setItem('isAuthenticated', Buffer.from(JSON.stringify(true)).toString('base64'))
        token.value = payloads.token
        user.value = payloads.user
        permission.value = payloads.permission
        isAuthenticated.value = true
    }

    const resetAuth = () => {
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        localStorage.removeItem('permission')
        localStorage.removeItem('isAuthenticated')
        user.value = {}
        token.value = ''
        permission.value = []
        isAuthenticated.value = false
    }

    const setSendEmail = (payloads) => {
        sendEmail.value = payloads.email
    }

    const setUser = (payloads) => {
        localStorage.setItem('user', Buffer.from(JSON.stringify(payloads)).toString('base64'))
        user.value = payloads
    }

    return {
        token,
        user,
        permission,
        notifikasiStatus,
        notifikasiMessage,
        notifikasiType,
        isAuthenticated,
        sendEmail,
        setNotifikasi,
        setAuth,
        resetAuth,
        setSendEmail,
        setUser
    }
})